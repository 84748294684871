import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MerchantComponent } from './merchant/merchant.component';
import { ArchivesComponent } from './merchant/pages/archives/archives.component';
import { CampaignListComponent } from './merchant/pages/campaign-list/campaign-list.component';
import { SetupComponent } from './merchant/pages/setup/setup.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ContentPreviewComponent } from './pages/content-preview/content-preview.component';
import { LoginComponent } from './pages/login/login.component';
import { OrderPageComponent } from './pages/order-page/order-page.component';
import { RegisterComponent } from './pages/register/register.component';
import { AuthGuard } from './shared/providers/auth guard/auth-guard';
const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'merchant',
    component: MerchantComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full',
      },
      {
        path: 'campaignList/:id',
        component: CampaignListComponent,
      },
      {
        path: 'campaignList/:id/:campaignId',
        component: CampaignListComponent,
      },
      {
        path: 'setup/:id',
        component: SetupComponent,
      },
      {
        path: 'setup/:id/:campaignId',
        component: SetupComponent,
      },
      {
        path: 'archives/:id',
        component: ArchivesComponent,
      },
    ]
  },
  {
    path: 'orderPage/:id/:campaignId',
    component: OrderPageComponent,
  },
  {
    path: 'checkout/:destinationId/:transactionId',
    component: CheckoutComponent,
  },
  {
    path: 'preview/:id/:campaignId',
    component: ContentPreviewComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
