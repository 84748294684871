import { Component } from '@angular/core';
import {
  Router,
  RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { WizzipayService } from './shared/providers/wizzipay.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'wizziPay';
  constructor(public router: Router, private service: WizzipayService) {
    router.events.subscribe((event: any): void => {
      this.navigationInterceptor(event);
    });
  }

  routerActivate(): void {
    window.scrollTo(0, 0);
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.service.ngxService.start();
    }
    if (event instanceof NavigationEnd) {
      this.service.ngxService.stop();
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.service.ngxService.stop();
    }
    if (event instanceof NavigationError) {
      this.service.ngxService.stop();
    }
  }
}

window.onbeforeunload = function () {
  window.onunload = function () {
    window.localStorage.isMySessionActive = 'false';
  };
  return undefined;
};

window.onload = function () {
  window.localStorage.isMySessionActive = 'true';
};
