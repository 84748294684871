<div class="container-fluid">
  <div class="row row-height row-eq-height">
    <div class="col-sm-12 mt-5 text-center">
      <h2>Insurance Quote Options</h2>
      <p *ngIf="dataRes?.status !== 'payment Ready' && dataRes?.status !== 'completed'">Click on the quote you like and
        fill out the preferred payment details on the right.</p>
    </div>
    <div class="col-lg-7 background-image px-0">
      <div class="content-left-wrapper">
        <div class="col-md-12">
          <div class="p-3 w-100">
            <h6>{{dataRes?.insuranceType}} Insurance Option</h6>
            <div class="tableHeight">
              <table class="table table-bordered mb-0">
                <thead>
                  <th>Package</th>
                  <th [ngClass]="{'columnColor': column == selectedQuoteColumn?.type}"
                    *ngFor="let column of parsedTableDatas[0]?.headers">
                    {{column}}
                  </th>
                </thead>
                <tbody>
                  <tr *ngIf="dataRes?.status !== 'payment Ready' && dataRes?.status !== 'completed'">
                    <td class="firstCol">Select any one</td>
                    <ng-container *ngFor="let item of selectQuoteButtonValues">
                      <td [ngClass]="{'columnColor': item.type == selectedQuoteColumn?.type}">
                        <button id="select" (click)="onSelect(item)" class="btn btn-type-two w-100" type="button">
                          <span>Select this quote</span>
                        </button>
                      </td>
                    </ng-container>
                  </tr>
                  <tr *ngFor="let row of parsedTableDatas[0]?.colPackage; index as i">
                    <td>{{row}}</td>
                    <td [ngClass]="{'columnColor': column == selectedQuoteColumn?.type}"
                      *ngFor="let column of parsedTableDatas[0]?.headers; index as j">
                      {{parsedTableDatas[0]?.colValues[j][i]}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-5"
      [ngStyle]="{'pointer-events': (dataRes?.status === 'payment Ready' || dataRes?.status === 'completed') ? 'none' : 'auto'}">
      <div class="container my-auto py-5">
        <div class="row">
          <div class="col-lg-12 col-xl-12 mx-auto">
            <form class="row justify-content-center" [formGroup]="bookingOrder">
              <div class="col-lg-12 text-end">
                <div class="logoImgStyle mb-3">
                  <img src="assets/insurance.png" width="100%">
                </div>
              </div>
              <div class="col-lg-6 col-md-6 mb-3">
                <insurance-input inputId="firstName" label="firstName" formControlName="firstName" [readOnly]="true">
                </insurance-input>
              </div>
              <div class="col-lg-6 col-md-6 mb-3">
                <insurance-input inputId="lastName" label="lastName" formControlName="lastName" [readOnly]="true">
                </insurance-input>
              </div>
              <div class="col-lg-6 col-md-6 mb-3">
                <insurance-input inputId="email" label="email" formControlName="email" [readOnly]="true">
                </insurance-input>
              </div>
              <div class="col-lg-6 col-md-6 mb-3">
                <insurance-input-date inputId="policyStartDate" label="Effective Date" [minDate]="startDate"
                  formControlName="policyStartDate">
                </insurance-input-date>
                <div
                  *ngIf="bookingOrder?.get('policyStartDate')?.touched && bookingOrder?.get('policyStartDate')?.errors">
                  <span class="p-error" *ngIf="bookingOrder?.get('policyStartDate')?.hasError('required')">
                    Date is required
                  </span>
                </div>
              </div>
              <div class="col-lg-12 mb-1">
                <h6 class="mb-0">All drivers phone numbers</h6>
              </div>
              <div class="col-lg-6 col-md-6" formArrayName="driverPhone"
                *ngFor="let data of driverPhone.controls;let i = index">
                <div class="row mb-3" [formGroupName]="i">
                  <div class=""
                    [ngClass]="[(dataRes?.status !== 'payment Ready' && dataRes?.status !== 'completed') ? 'col-11' : 'col-12']">
                    <insurance-input-mask inputId="number" label="Driver Phone {{i + 1}}" formControlName="number">
                    </insurance-input-mask>
                  </div>
                  <div class="col-sm-1 col-1 iconStyle"
                    *ngIf="dataRes?.status !== 'payment Ready' && dataRes?.status !== 'completed'">
                    <i class="fa fa-minus" id="removeEmbryoDetails{{i}}" (click)="removeDriver(i)"
                      *ngIf="i !== driverPhone.controls.length - 1"></i>
                    <i class="fa fa-plus" id="addEmbryoDetails" (click)="addDriver(driverPhone?.controls, i)"
                      *ngIf="i === driverPhone.controls.length - 1"></i>
                  </div>
                  <div *ngIf="data?.get('number')?.touched && data?.get('number')?.errors">
                    <span class="p-error" *ngIf="data?.get('number')?.hasError('required')">
                      Phone number is Required
                    </span>
                    <span class="p-error" *ngIf="data?.get('number')?.hasError('pattern')">
                      Please enter a valid phone number
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 mb-3">
                <insurance-select [dataList]="paymentMethodOption" dataLabel="name" dataValue="name"
                  inputId="paymentMethod" label="Payment Method" formControlName="paymentMethod"
                  (onClick)="selectMethod($event)">
                </insurance-select>
                <div *ngIf="bookingOrder?.get('paymentMethod')?.touched && bookingOrder?.get('paymentMethod')?.errors">
                  <span class="p-error" *ngIf="bookingOrder?.get('paymentMethod')?.hasError('required')">
                    Payment method is required
                  </span>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 mb-3">
                <insurance-select [dataList]="selectedCarrierData" dataLabel="paymentMode" dataValue="paymentMode"
                  label="Payment Mode" formControlName="paymentMode" (onClick)="selectPayment($event)">
                </insurance-select>
                <div *ngIf="bookingOrder?.get('paymentMode')?.touched && bookingOrder?.get('paymentMode')?.errors">
                  <span class="p-error" *ngIf="bookingOrder?.get('paymentMode')?.hasError('required')">
                    Payment mode is required
                  </span>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 mb-3">
                <insurance-input-number inputId="amount" label="amount" formControlName="amount" [readOnly]="true">
                </insurance-input-number>
                <div class="p-error"
                  *ngIf="bookingOrder?.get('amount')?.hasError('required') && bookingOrder?.get('amount')?.touched">
                  Please select amount
                </div>
              </div>
              <ng-container *ngIf="dataRes?.status !== 'payment Ready' && dataRes?.status !== 'completed'">
                <div class="col-lg-12 mb-3 text-danger"><i class="pe-2 fa fa-exclamation-circle"
                    aria-hidden="true"></i>Changing effective date may result in loss of bind in advance discounts</div>
                <div class="col-lg-12 mb-3">
                  <insurance-checkbox formControlName="checkEffectiveDate"
                    label=" I need coverage sooner than the effective date provided" [multiCheckBox]="false">
                  </insurance-checkbox>
                </div>
                <div class="col-lg-12 text-center">
                  <insurance-button btnWidth="150" (onClick)="submitBooking()" label="Submit" [loading]="submitting">
                  </insurance-button>
                </div>
              </ng-container>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modalWithClose">
  <insurance-popup [displayModal]="displayDialog" [showHeader]="false" iconBgColor="#e1f3fc" iconClass="pi pi-info"
    iconColor="#005b88" message="Order Confirmed">
    <div class="mt-3">
      <h6 class="mb-2">“Thank you for placing your order. You should expect to receive a confirmation email shortly with
        your proof of insurance and a receipt. Our team will reach out with any further questions.”</h6>
      <insurance-button btnWidth="200" (onClick)="routeToPayment()" label="Proceed To Payment">
      </insurance-button>
    </div>
  </insurance-popup>
</div>