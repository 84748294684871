import { BrowserModule } from '@angular/platform-browser';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, DecimalPipe } from '@angular/common';
import { NgxStripeModule } from 'ngx-stripe';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './pages/login/login.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { OrderPageComponent } from './pages/order-page/order-page.component';
import { RegisterComponent } from './pages/register/register.component';
import { LibrariesModule } from '@git2swarm/hhinsurance-web-components';
import { MerchantComponent } from './merchant/merchant.component';
import { ArchivesComponent } from './merchant/pages/archives/archives.component';
import { CampaignListComponent } from './merchant/pages/campaign-list/campaign-list.component';
import { OrderSetupComponent } from './merchant/pages/setup/component/order-setup/order-setup.component';
import { OrderpagePreviewComponent } from './merchant/pages/setup/component/orderpage-preview/orderpage-preview.component';
import { PaymentPreviewComponent } from './merchant/pages/setup/component/payment-preview/payment-preview.component';
import { SetupComponent } from './merchant/pages/setup/setup.component';
import { QuillModule } from 'ngx-quill';
import { AppHelper } from './app.helper';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AuthGuardService } from './shared/providers/auth guard/auth.service';
import { HttpTokenInterceptor } from './shared/providers/httpinterceptor/http-interceptor.service';
import { ContentPreviewComponent } from './pages/content-preview/content-preview.component';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'cube-grid',
  blur: 15,
  delay: 0,
  fgsColor: '#ffffff',
  fgsPosition: 'center-center',
  fgsSize: 40,
  fgsType: 'cube-grid',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 10,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: '#007cba',
  pbColor: '#ffffff',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 300,
};
export function jwtOptionsFactory(AppHelper: AppHelper) {
  return {
    tokenGetter: () => AppHelper.secureStorage('get', 'token'),
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    OrderPageComponent,
    CheckoutComponent,
    MerchantComponent,
    CampaignListComponent,
    SetupComponent,
    OrderpagePreviewComponent,
    OrderSetupComponent,
    PaymentPreviewComponent,
    ArchivesComponent,
    ContentPreviewComponent,
  ],
  imports: [
    NgxStripeModule.forRoot(),
    QuillModule.forRoot(),
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LibrariesModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AppHelper],
      },
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    DecimalPipe,
    DatePipe,
    AppHelper,
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
