import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppHelper } from 'src/app/app.helper';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  email = this.fb.group({
    email: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });
  hide = true;
  submitting = false;

  constructor(
    public fb: FormBuilder,
    public service: WizzipayService,
    public router: Router,
    public helper: AppHelper
  ) {}

  ngOnInit(): void {
    if (
      this.helper.secureStorage('get', 'token') &&
      this.helper.secureStorage('get', 'merchantId')
    ) {
      this.helper.secureStorage('remove', 'token');
      this.helper.secureStorage('remove', 'merchantId');
    }
  }

  keyDownFunction(event: { keyCode: number }) {
    if (event.keyCode === 13) {
      this.submit();
    }
  }

  submit() {
    this.hide = false;
    this.submitting = true;
    if (this.email.invalid) {
      this.submitting = false;
      this.email.markAllAsTouched();
      return;
    }
    this.service.login(this.email.value).subscribe(
      (res): void => {
        this.submitting = false;
        this.helper.secureStorage('set', 'token', res.data.token);
        this.helper.secureStorage('set', 'merchantId', res.data.merchantId);
        this.router.navigate([
          `/merchant/campaignList/${res.data.merchantId}/`,
        ]);
        this.email.reset();
      },
      (err): void => {
        this.submitting = false;
        this.service.snackbar(err.error.message);
      }
    );
  }
}
