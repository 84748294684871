<main class="d-flex mb-3">
    <div class="card formSection">
        <div class="card-header">
            <div class="jumbotron text-center">
                <h1 class="display-6">Customer Quote Offer Page Setup</h1>
            </div>
        </div>
        <div class="card-body">
            <div class="row no-gutters">
                <div class="col-lg-12">
                    <div class="row">
                        <form [formGroup]="productDetail">
                            <div class="col-lg-12 mb-64">
                                <div class="row justify-content-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="Divider">
                                            <hr />
                                            <p class="Divider-Text Text text-color-gray text-font-14 text-font-400">
                                                Insurance Type
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2 col-md-3 mb-3"
                                                *ngFor="let data of targetOptionList; let i = index">
                                                <insurance-radiobutton formControlName="insuranceType" name="country"
                                                    value="{{data.name}}" (onClick)="getTable()" inputId="{{data.name}}"
                                                    label="{{data.name}}">
                                                </insurance-radiobutton>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 mt-3 mb-3">
                                        <insurance-select [dataList]="carrierList" dataLabel="name" dataValue="name"
                                            inputId="carrier" label="Carrier Name" formControlName="carrier"
                                            (onClick)="selectMethod($event)">
                                        </insurance-select>
                                        <div
                                            *ngIf="productDetail?.get('carrier')?.touched && productDetail?.get('carrier')?.errors">
                                            <span class="p-error"
                                                *ngIf="productDetail?.get('carrier')?.hasError('required')">
                                                Carrier Name is required
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-8 col-md-12 mb-3" *ngIf="true">
                                        <insurance-file-upload docType="PDF" uploadId="app"
                                            [filename]="packageDoc.value.name" [filesize]="packageDoc.value.size"
                                            url="{{environmentURL}}/api/pdf/parser/uploadfile/"
                                            secondUrl="{{environmentURL}}/api/v1/backend/file/upload"
                                            secondFormDataName="files" (uploadedFileRes)="getPDFRes($event)"
                                            (secondAPIRes)="getSecondRes($event)" (deletedFileRes)="deletedImgRes()"
                                            acceptExt="application/pdf">
                                        </insurance-file-upload>
                                    </div>
                                    <div class="col-lg-12 text-center">
                                        <div class="Divider">
                                            <hr />
                                            <p class="Divider-Text Text text-color-gray text-font-14 text-font-400">
                                                Customer Details
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mb-3">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-6 col-md-6 mb-3" formGroupName="customer">
                                                <insurance-input inputId="firstName" label="First Name"
                                                    formControlName="firstName"></insurance-input>
                                                <div
                                                    *ngIf="customer?.get('firstName')?.touched && customer?.get('firstName')?.errors">
                                                    <span class="p-error"
                                                        *ngIf="customer?.get('firstName')?.hasError('required')">
                                                        First name is required
                                                    </span>
                                                    <span class="p-error"
                                                        *ngIf="customer?.get('firstName')?.hasError('pattern')">
                                                        Please enter a valid first name
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 mb-3" formGroupName="customer">
                                                <insurance-input inputId="lastName" label="Last Name"
                                                    formControlName="lastName"></insurance-input>
                                                <div
                                                    *ngIf="customer?.get('lastName')?.touched && customer?.get('lastName')?.errors">
                                                    <span class="p-error"
                                                        *ngIf="customer?.get('lastName')?.hasError('required')">
                                                        Last name is required
                                                    </span>
                                                    <span class="p-error"
                                                        *ngIf="customer?.get('lastName')?.hasError('pattern')">
                                                        Please enter a valid last name
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 mb-3" formGroupName="customer">
                                                <insurance-input inputId="email" label="Email" formControlName="email">
                                                </insurance-input>
                                                <div
                                                    *ngIf="customer?.get('email')?.touched && customer?.get('email')?.errors">
                                                    <span class="p-error"
                                                        *ngIf="customer?.get('email')?.hasError('required')">
                                                        Email is required
                                                    </span>
                                                    <span class="p-error"
                                                        *ngIf="customer?.get('email')?.hasError('email')">
                                                        Please enter a valid email
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 mb-3" formArrayName="driverPhone"
                                                *ngFor="let data of driverPhone.controls;let i = index">
                                                <div class="row mb-3" [formGroupName]="i">
                                                    <div class="col-sm-11 col-10">
                                                        <insurance-input-mask inputId="number"
                                                            label="Driver Phone {{i + 1}}" formControlName="number">
                                                        </insurance-input-mask>
                                                    </div>
                                                    <div class="col-sm-1 col-2 three">
                                                        <i class="fa fa-minus" id="removeEmbryoDetails{{i}}"
                                                            (click)="removeDriver(i)"
                                                            *ngIf="i !== driverPhone.controls.length - 1"></i>
                                                        <i class="fa fa-plus" id="addEmbryoDetails"
                                                            (click)="addDriver(driverPhone?.controls, i)"
                                                            *ngIf="i === driverPhone.controls.length - 1"></i>
                                                    </div>
                                                    <div
                                                        *ngIf="data?.get('number')?.touched && data?.get('number')?.errors">
                                                        <span class="p-error"
                                                            *ngIf="data?.get('number')?.hasError('required')">
                                                            Phone number is Required
                                                        </span>
                                                        <span class="p-error"
                                                            *ngIf="data?.get('number')?.hasError('pattern')">
                                                            Please enter a valid phone number
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 text-center">
                                        <div class="Divider">
                                            <hr />
                                            <p class="Divider-Text Text text-color-gray text-font-14 text-font-400">
                                                Agent Details
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mb-3">
                                        <div class="row justify-content-center" formGroupName="agent">
                                            <div class="col-lg-6 col-md-6 mb-3">
                                                <insurance-input inputId="agentfirstName" label="First Name"
                                                    formControlName="firstName"></insurance-input>
                                                <div
                                                    *ngIf="agent?.get('firstName')?.touched && agent?.get('firstName')?.errors">
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('firstName')?.hasError('required')">
                                                        Agent first name is required
                                                    </span>
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('firstName')?.hasError('pattern')">
                                                        Please enter a valid agent first name
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 mb-3">
                                                <insurance-input inputId="agentlastName" label="Last Name"
                                                    formControlName="lastName"></insurance-input>
                                                <div
                                                    *ngIf="agent?.get('lastName')?.touched && agent?.get('lastName')?.errors">
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('lastName')?.hasError('required')">
                                                        Agent last name is required
                                                    </span>
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('lastName')?.hasError('pattern')">
                                                        Please enter a valid agent last name
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 mb-3">
                                                <insurance-input inputId="agentEmail" label="Email"
                                                    formControlName="email">
                                                </insurance-input>
                                                <div
                                                    *ngIf="agent?.get('email')?.touched && agent?.get('email')?.errors">
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('email')?.hasError('required')">
                                                        Agent email is required
                                                    </span>
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('email')?.hasError('email')">
                                                        Please enter a valid agent email
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 mb-3">
                                                <insurance-input-mask inputId="agentNumber" label="Phone"
                                                    formControlName="number">
                                                </insurance-input-mask>
                                                <div
                                                    *ngIf="agent?.get('number')?.touched && agent?.get('number')?.errors">
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('number')?.hasError('required')">
                                                        Agent phone number is Required
                                                    </span>
                                                    <span class="p-error"
                                                        *ngIf="agent?.get('number')?.hasError('pattern')">
                                                        Please enter a valid agent phone number
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-lg-6 col-md-6 mb-3">
                                                <insurance-input-date inputId="policyStartDate" label="Effective Date"
                                                    formControlName="policyStartDate" [minDate]="startDate">
                                                </insurance-input-date>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-3 mb-3">
                                        <div class="table-responsive">
                                            <table class="table table-bordered table-wid mb-0"
                                                *ngIf="parsedTableDatas[0]?.headers?.length > 0">
                                                <thead>
                                                    <th>Package</th>
                                                    <th *ngFor="let column of parsedTableDatas[0]?.headers">
                                                        {{column}}
                                                    </th>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let row of parsedTableDatas[0]?.colPackage; index as i">
                                                        <td>{{row}}</td>
                                                        <td
                                                            *ngFor="let column of parsedTableDatas[0]?.headers; index as j">
                                                            {{parsedTableDatas[0]?.colValues[j][i]}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <ng-container
                                                *ngIf="!parsedTableDatas[0]?.headers && parsedTableDatas.length > 0">
                                                <div class="col-lg-12 text-center">
                                                    <h5>Proposal not found</h5>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 text-center">
                                <insurance-button class="float-md-end mb-3" btnWidth="150" label="Next"
                                    (onClick)="submit()" [loading]="submitting">
                                </insurance-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>