import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';
import * as orderPageData from '../carriers.data';

@Component({
  selector: 'app-order-page',
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.css'],
})
export class OrderPageComponent implements OnInit {
  bookingOrder: FormGroup = this.fb.group({
    firstName: [''],
    lastName: [''],
    email: [''],
    phoneCode: [''],
    phone: [''],
    amount: [null, [Validators.required]],
    purpose: '',
    policyStartDate: ['', [Validators.required]],
    paymentMode: ['', [Validators.required]],
    paymentMethod: ['', [Validators.required]],
    packageName: [''],
    driverPhone: this.fb.array([this.newDriverNumber()]),
    checkEffectiveDate: [false],
  });
  startDate = new Date();
  merchantId = '';
  campaignId = '';
  dataRes: { [key: string]: any } = {};
  paymentMethodOption = orderPageData.paymentMethodOption;
  selectedCarrierData: any[] = [];
  selectQuoteButtonValues: any[] = [];
  selectedQuoteColumn: { [key: string]: string } = {};
  selectedQuoteAmount = 0;
  submitting = false;
  transactionId = '';
  displayDialog = false;
  parsedTableDatas: any[] = [];

  constructor(
    private fb: FormBuilder,
    public activatedRoute: ActivatedRoute,
    public wizzipayServiceAPI: WizzipayService,
    public router: Router,
    private datepipe: DatePipe
  ) {}

  get driverPhone(): FormArray {
    return this.bookingOrder.get('driverPhone') as FormArray;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.merchantId = params['id'];
      this.campaignId = params['campaignId'];
      this.getData();
    });
  }

  addDriver(control: any, index: number) {
    control[index].get('number').markAllAsTouched();
    if (control[index].get('number').invalid) {
      this.wizzipayServiceAPI.snackbar('Please fill this number to add more');
      return;
    }
    const array = this.bookingOrder.get('driverPhone') as FormArray;
    array.push(this.newDriverNumber());
  }

  removeDriver(i: number) {
    const array = this.bookingOrder.get('driverPhone') as FormArray;
    array.removeAt(i);
  }

  newDriverNumber(): FormGroup {
    return this.fb.group({
      number: [
        '',
        [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)],
      ],
    });
  }

  getData() {
    this.wizzipayServiceAPI
      .getdata(this.merchantId, this.campaignId)
      .subscribe((res) => {
        // FIND CARRIER
        this.selectedCarrierData = orderPageData.carrierTyes.filter(
          (car: any) => {
            car.package = car.package.filter((pack: any) => {
              return pack.carrier.includes(res.data.carrier);
            });
            return car.package.length > 0;
          }
        );
        // ASSIGNING VALUES TO 'SELECT THIS QUOTE' BUTTON START
        let payload: { [key: string]: string } = {};
        //let test:any =[]
        res.data.package[0]?.headers.forEach((val:any, baseindex:any)=>{
          payload = {
            type: val,
          };
          res.data.package[0]?.colPackage.forEach((colVal:any, index:any)=>{
            this.selectedCarrierData.forEach((data: any) => {
              data.package.forEach((pack: any) => {
                if (colVal == pack.value) {
                  payload[pack.key] = res.data.package[0]?.colValues[baseindex][index];
                }
              });
            });
          });
          this.selectQuoteButtonValues.push(payload)
        })
        // res.data.package.forEach(
        //   (element: any) => {
        //     let payload: { [key: string]: string } = {};
        //     payload = {
        //       type: element.name,
        //     };
        //     count++;
        //     for (const key in element) {
        //       this.selectedCarrierData.forEach((data: any, index) => {
        //         data.package.forEach((pack: any) => {
        //           if (key == pack.value) {
        //             payload[pack.key] = element[key];
        //           }
        //         });
        //       });
        //     }
        //     this.selectQuoteButtonValues.push(payload);
        //     console.log('button', this.selectQuoteButtonValues)
        //   }
        // );
        // res.data.package.forEach(
        //   (element: { [x: string]: string; name: string }) => {
        //     let payload: { [key: string]: string } = {};
        //     payload = {
        //       type: element.name,
        //     };
        //     count++;
        //     for (const key in element) {
        //       this.selectedCarrierData.forEach((data: any, index) => {
        //         data.package.forEach((pack: any) => {
        //           if (key == pack.value) {
        //             payload[pack.key] = element[key];
        //           }
        //         });
        //       });
        //     }
        //     this.selectQuoteButtonValues.push(payload);
        //     console.log('button', this.selectQuoteButtonValues)
        //   }
        // );
        // ASSIGNING VALUES TO 'SELECT THIS QUOTE' BUTTON END

        if (res.data.package.length > 0) {
          // QUOTE TABLE DATAS START
          // const payload: { [key: string]: any } = {};
          // var headers: string[] = [];
          // const colPackage: string[] = []; //Table Package Name
          // const colValues: string[][] = [];
          // res.data.package.forEach(
          //   (sub: { [key: string]: string }, index: number) => {
          //     const rowValues: string[] = [];
          //     for (const key in res.data.package[0]) {
          //       if (key == 'name') {
          //         headers.push(sub.name);
          //       } else if (index == 0) {
          //         colPackage.push(key);
          //       }
          //       if (key != 'name') {
          //         rowValues.push(sub[key]);
          //       }
          //     }
          //     colValues.push(rowValues);
          //   }
          // );
          // payload['headers'] = headers;
          // payload['colPackage'] = colPackage;
          // payload['colValues'] = colValues;
          // this.parsedTableDatas.push(payload);
          this.parsedTableDatas = res.data.package;
          // QUOTE TABLE DATAS END
        }
        // QUOTE TABLE DATAS END

        this.dataRes = res.data;
        this.patchData(res.data);
      });
  }

  patchData(data: any) {
    this.bookingOrder.patchValue({
      firstName: data?.customer?.firstName,
      lastName: data?.customer?.lastName,
      email: data?.customer?.email,
      phoneCode: data?.customer?.dialCode,
      phone: data?.customer?.number,
    });
    if (
      data?.status === 'in progress' ||
      data?.status === 'payment Ready' ||
      data?.status === 'completed'
    ) {
      this.bookingOrder.patchValue({
        paymentMethod: data?.paymentHistory?.paymentMethod,
        paymentMode: data?.paymentHistory?.paymentMode,
        amount: data?.paymentHistory?.amount / 100,
        packageName: data?.paymentHistory?.packageName,
      });
      const selectQuoteButtonValues: any = this.selectQuoteButtonValues.find(
        (element) => element.type === data?.paymentHistory?.packageName
      );
      this.selectedQuoteColumn = selectQuoteButtonValues;
      if (data?.paymentHistory?.driverPhone.length > 0) {
        this.bookingOrder.setControl(
          'driverPhone',
          this.resDriverNumber(data?.paymentHistory?.driverPhone)
        );
      }
    } else {
      if (data?.driverPhone?.length > 0) {
        this.bookingOrder.setControl(
          'driverPhone',
          this.resDriverNumber(data?.driverPhone)
        );
      }
    }
    if (data?.paymentHistory?.policyStartDate) {
      this.bookingOrder.patchValue({
        policyStartDate: this.datepipe.transform(
          data?.paymentHistory?.policyStartDate,
          'MM/dd/yyyy'
        ),
      });
    } else {
      this.bookingOrder.patchValue({
        policyStartDate: this.datepipe.transform(
          data?.policyStartDate,
          'MM/dd/yyyy'
        ),
      });
    }
  }

  resDriverNumber(driverNum: Array<{ [key: string]: string }>): FormArray {
    const formArray = new FormArray([]);
    driverNum.forEach((element) => {
      formArray.push(
        this.fb.group({
          number: element.number,
        })
      );
    });
    return formArray;
  }

  returnZero() {
    return 0;
  }

  trackByFn(item: number) {
    return item;
  }

  // SELECT THIS QUOTE BUTTON FUNCTION START
  onSelect(item: { [x: string]: string; type: string }) {
    this.selectedQuoteColumn = item;
    this.bookingOrder.patchValue({ packageName: item.type });
    if (
      this.bookingOrder.value.paymentMethod &&
      this.bookingOrder.value.paymentMode
    ) {
      this.getAmount(
        this.bookingOrder.value.paymentMethod,
        this.bookingOrder.value.paymentMode
      );
    }
  }
  // SELECT THIS QUOTE BUTTON FUNCTION END

  // SELECT PAYMENT METHOD FUNCTION START
  selectMethod(item: string) {
    if (
      this.bookingOrder.value.packageName &&
      this.bookingOrder.value.paymentMode
    ) {
      this.getAmount(item, this.bookingOrder.value.paymentMode);
    }
  }
  // SELECT PAYMENT METHOD FUNCTION END

  // SELECT PAYMENT MODE FUNCTION START
  selectPayment(value: string) {
    if (
      this.bookingOrder.value.packageName &&
      this.bookingOrder.value.paymentMethod
    ) {
      this.getAmount(this.bookingOrder.value.paymentMethod, value);
    }
  }
  // SELECT PAYMENT MODE FUNCTION END

  getAmount(paymentMethod: string, paymentMode: string) {
    console.log('payment method', paymentMethod)
    console.log('paymentMode', paymentMode)
    const filterByMode = this.selectedCarrierData.find(
      (res: any) => res.paymentMode == paymentMode
    );
    console.log('filter ', filterByMode)
    console.log('this.selectedQuoteColumn ', this.selectedQuoteColumn)
    filterByMode.package.forEach((res: any) => {
      if (res.paymentMethod.includes(paymentMethod)) {
        console.log('testingggg ', res.key ,this.selectedQuoteColumn[res.key])
        this.bookingOrder.patchValue({
          amount: parseFloat(
            this.selectedQuoteColumn[res.key].replace(/\$|,/g, '')
          ),
        });
      }
    });
  }

  submitBooking() {
    this.submitting = true;
    this.bookingOrder.markAllAsTouched();
    if (this.bookingOrder.invalid) {
      this.submitting = false;
      this.wizzipayServiceAPI.snackbar('Please fill all the fields');
      return;
    }
    if (this.bookingOrder.invalid) {
      this.submitting = false;
      this.wizzipayServiceAPI.snackbar('Please fill all the fields');
      return;
    }
    const payload = {
      userDetails: {
        firstName: this.bookingOrder.value.firstName,
        lastName: this.bookingOrder.value.lastName,
        phoneCode: '+' + this.bookingOrder.value.phoneCode,
        phoneNumber: this.bookingOrder.value.phone,
        email: this.bookingOrder.value.email,
      },
      paymentDetails: {
        tripName: this.bookingOrder.value.purpose,
        amount: this.bookingOrder.value.amount,
      },
      packageName: this.bookingOrder.value.packageName,
      merchantId: this.merchantId,
      campaignId: this.campaignId,
      driverPhone: this.bookingOrder.value.driverPhone,
      policyStartDate: this.bookingOrder.value.policyStartDate,
      paymentMode: this.bookingOrder.value.paymentMode,
      paymentMethod: this.bookingOrder.value.paymentMethod,
    };
    this.wizzipayServiceAPI.orderData(payload).subscribe(
      (res) => {
        if (res.status == true) {
          this.transactionId = res?.data?.transactionId;
          this.displayDialog = true;
          this.submitting = false;
          this.bookingOrder.reset();
        }
      },
      (err) => {
        if (err) {
          this.submitting = false;
          this.wizzipayServiceAPI.snackbar('Error');
        }
      }
    );
  }

  routeToPayment() {
    this.router.navigate([
      `/checkout/${this.merchantId}/${this.transactionId}`,
    ]);
  }
}
