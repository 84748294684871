import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.css'],
})
export class CampaignListComponent implements OnInit {
  emailForm: FormGroup = this.fb.group({
    checkAll: false,
    paymentMode: 'Full payment',
    emailCC: this.fb.array([this.newEmail()]),
    proposal: ['', [Validators.required]],
    smsMessage: ['', [Validators.required]],
  });
  searchText: any;
  merchantId = '';
  environmentURL = '';
  paramsCampaignId = '';
  merchantresponse = '';
  pageSize = 10;
  pageOffset = 0;
  stopSort = true;
  campaignResponse: { [key: string]: any }[] = [];
  totalCount = 0;
  selectedQuote: string[] = [];
  deleteSelect = new FormControl(false);
  asc = false;
  dsc = false;
  sortTypes: any = [
    {
      label: 'Name',
      sort: 'nameAsc',
    },
    {
      label: 'Insurance Type',
      sort: 'typeAsc',
    },
    {
      label: 'Agent',
      sort: 'agentNameAsc',
    },
    {
      label: 'Status',
      sort: 'statusAsc',
    },
  ];
  btnLable = 'Sort By';
  sortOrderName = '';
  sortType = '';
  showPaymentDialog = false;
  paymentData: { [key: string]: string } = {};
  emailDialog = false;
  campaignId = '';
  emailContent = '';
  attachmentFile: { [key: string]: string } = {};
  public moduleConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ['clean'], // remove formatting button
      ['link'],
    ],
  };
  submitting = false;
  smsDialog = false;
  currentPage = 0;
  pageEvent: any;

  constructor(
    public fb: FormBuilder,
    public activatedRoute: ActivatedRoute,
    public service: WizzipayService,
    public router: Router
  ) {
    this.environmentURL = environment.autoPortalURL;
  }

  get emailCCType(): FormArray {
    return this.emailForm.get('emailCC') as FormArray;
  }

  ngOnInit(): void {
    this.searchText = new FormControl('');
    this.searchText.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.getData('', '', this.searchText.value);
      });
    this.activatedRoute.params.subscribe((params) => {
      this.merchantId = params['id'];
      if (params['campaignId']) {
        this.paramsCampaignId = params['campaignId'];
      }
      this.getMerchantData();
      this.getData('', '', this.searchText.value);
    });
    console.log('Auto Portal Update - 20-04-2024')
  }

  getMerchantData() {
    this.service.getMerchantData(this.merchantId).subscribe((res) => {
      this.merchantresponse = res?.data?.email;
    });
  }

  setPage() {
    this.currentPage = 0;
  }

  getData(sort: string, status: string, search: string) {
    this.stopSort = true;
    this.service
      .getcampaignData(
        this.merchantId,
        sort,
        status,
        this.pageSize,
        this.pageOffset,
        search
      )
      .subscribe((res) => {
        this.campaignResponse = [];
        this.totalCount = res.data.totalCount;
        res.data.data.forEach((element: any) => {
          if (sort) {
            this.stopSort = false;
          }
          const payload = {
            _id: element._id,
            merchantId: element.merchantId,
            name:
              (element?.customer?.firstName || '') +
              ' ' +
              (element?.customer?.lastName || ''),
            agentName:
              (element?.agent?.firstName || '') +
              ' ' +
              (element?.agent?.lastName || ''),
            insuranceType: element?.insuranceType,
            packageName: element?.paymentHistory?.packageName,
            amount: this.returnAmount(element?.paymentHistory?.amount),
            policyStartDate: element?.policyStartDate,
            selectedDate: element?.paymentHistory?.policyStartDate
              ? element?.paymentHistory?.policyStartDate
              : '',
            progress: element?.status,
            carrierName: element?.carrier,
            package: element?.package,
            packageDoc: element?.packageDoc,
            driverPhone:
              element?.paymentHistory?.driverPhone?.length > 0
                ? element?.paymentHistory?.driverPhone
                : element?.driverPhone,
            proposal: element?.proposal,
            checked: false,
            payment: element?.paymentHistory,
            emailContent: element?.emailContent,
          };
          this.campaignResponse.push(payload);
        });
      });
  }

  returnAmount(value: number) {
    return value / 100;
  }

  selectAll(e: boolean) {
    if (e) {
      this.selectedQuote = [];
      for (let i = 0; i < this.campaignResponse.length; i++) {
        this.campaignResponse[i].checked = true;
        this.selectedQuote.push(this.campaignResponse[i]._id);
      }
    } else {
      for (let i = 0; i < this.campaignResponse.length; i++) {
        this.campaignResponse[i].checked = false;
        const index = this.selectedQuote.indexOf(this.campaignResponse[i]._id);
        if (index > -1) {
          this.selectedQuote.splice(index, 1);
        }
      }
    }
  }

  sort(type: string, sort: string) {
    this.stopSort = false;
    this.sortOrderName = sort;
    this.sortType = type;
    if (
      this.sortOrderName == 'nameAsc' ||
      this.sortOrderName == 'typeAsc' ||
      this.sortOrderName == 'statusAsc' ||
      this.sortOrderName == 'agentNameAsc'
    ) {
      this.btnLable = this.sortType;
      const isClearSort = this.sortTypes.some(function (el: { label: string }) {
        return el.label === 'Clear Sort';
      });
      if (!isClearSort) {
        const payload = {
          label: 'Clear Sort',
          sort: '',
        };
        this.sortTypes.push(payload);
      }
      this.asc = true;
      this.dsc = false;
    } else {
      if (this.sortType == 'Clear Sort') {
        this.btnLable = 'Sort By';
        const payload = {
          label: 'Clear Sort',
          sort: '',
        };
        this.sortTypes.pop(payload);
      }
      this.asc = false;
      this.dsc = false;
    }
    this.getData(this.sortOrderName, '', this.searchText);
  }

  sortOrder() {
    if (this.sortType == 'Name') {
      if (this.sortOrderName === 'nameAsc') {
        this.asc = false;
        this.dsc = true;
        this.sortOrderName = 'nameDesc';
      } else {
        this.asc = true;
        this.dsc = false;
        this.sortOrderName = 'nameAsc';
      }
      this.getData(this.sortOrderName, '', this.searchText);
    } else if (this.sortType == 'Insurance Type') {
      if (this.sortOrderName === 'typeAsc') {
        this.asc = false;
        this.dsc = true;
        this.sortOrderName = 'typeDesc';
      } else {
        this.asc = true;
        this.dsc = false;
        this.sortOrderName = 'typeAsc';
      }
      this.getData(this.sortOrderName, '', this.searchText);
    } else if (this.sortType == 'Status') {
      if (this.sortOrderName === 'statusAsc') {
        this.asc = false;
        this.dsc = true;
        this.sortOrderName = 'statusDesc';
      } else {
        this.asc = true;
        this.dsc = false;
        this.sortOrderName = 'statusAsc';
      }
      this.getData(this.sortOrderName, '', this.searchText);
    } else if (this.sortType == 'Agent') {
      if (this.sortOrderName === 'agentNameAsc') {
        this.asc = false;
        this.dsc = true;
        this.sortOrderName = 'agentNameDesc';
      } else {
        this.asc = true;
        this.dsc = false;
        this.sortOrderName = 'agentNameAsc';
      }
      this.getData(this.sortOrderName, '', this.searchText);
    }
  }

  selectQuote(id: string, i: number, e: boolean) {
    if (e) {
      this.selectedQuote.push(id);
      this.campaignResponse[i].checked = true;
    } else {
      const index = this.selectedQuote.indexOf(id);
      if (index > -1) {
        this.campaignResponse[i].checked = false;
        this.selectedQuote.splice(index, 1);
      }
    }
    if (this.selectedQuote.length === this.campaignResponse.length) {
      this.deleteSelect.patchValue(true);
    } else {
      this.deleteSelect.reset(false);
    }
  }

  archieveData(data: any): void {
    this.service.archieveOffer(data.merchantId, data._id).subscribe(() => {
      this.getData('', '', this.searchText);
      this.service.snackbar('Offer moved to archieve');
      this.asc = false;
      this.dsc = false;
      this.btnLable = 'Sort By';
      const isClearSort = this.sortTypes.some(function (el: { label: string }) {
        return el.label === 'Clear Sort';
      });
      if (isClearSort) {
        const payload = {
          label: 'Clear Sort',
          sort: '',
        };
        this.sortTypes.pop(payload);
      }
    });
  }

  getPayment(data: {
    transactionId: string;
    paymentMethod: string;
    paymentMode: string;
  }) {
    this.service.getCardDetails(data.transactionId).subscribe((res) => {
      this.showPaymentDialog = true;
      this.paymentData = res?.data?.cc || res?.data?.ib;
      this.paymentData['paymentType'] = res?.data?.cc
        ? 'Credit Card'
        : 'Bank Transfer';
      this.paymentData['cardNumber'] = res?.data?.cc
        ? res?.data?.cc?.cardNumber.match(/.{1,4}/g).join(' ')
        : '';
      this.paymentData['paymentMethod'] = data.paymentMethod;
      this.paymentData['paymentMode'] = data.paymentMode;
    });
  }

  closePaymentDialog() {
    this.showPaymentDialog = false;
  }

  routeToOrder(data: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([
        '/orderPage/' + this.merchantId + '/' + data._id,
      ])
    );
    window.open(url, '_blank');
  }

  callSnack(text: string) {
    this.service.snackbar(text);
  }

  routeToSetup(data: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([
        '/merchant/setup/' + this.merchantId + '/' + data._id,
      ])
    );
    window.open(url, '_blank');
  }

  emailPreview(value: any) {
    if (value?.emailContent) {
      this.emailContent = value?.emailContent;
      this.campaignId = value?._id;
      this.attachmentFile = value?.packageDoc;
      this.emailForm.patchValue({
        proposal: 'HH Insurance - Private Passenger Automobile Proposal',
      });
      this.emailDialog = true;
      return;
    }
    const emailRouteUrl = `${this.environmentURL}/orderPage/${value?.merchantId}/${value?._id}`;
    this.emailForm.patchValue({
      proposal: 'HH Insurance - Private Passenger Automobile Proposal',
    });
    let packageList = `
    <li>Basic - This would be the most cost-effective option while still providing adequate coverage</li>
    <li>Apples to Apples - This option matches what you currently have with State Farm, but with a 13% rate decrease. That’s an annualized savings of $500.</li>
    <li>Enhanced - On this option all coverages remain the same except I have included a comprehensive/collision deductible of $500</li>
    <li>Premier Enhanced -I have increased liability and uninsured motorist limits to the next highest tier which is $100,000/$300,000</li>
    <li>Premier Enhanced Plus - I have increased liability and uninsured motorist limits to the next highest tier there is which is $250,000/$500,000 and included the responsible driver plan, which offers accident and minor violation forgiveness. One accident and one violation is forgiven every 36 months, starts immediately</li>
    `;
    // value?.package[0]?.headers.forEach((element: string) => {
    //   // packageList += `<li>${element} -</li>`;
    //   if (element.toLowerCase().includes('basic')) {
    //     packageList += `<li>${element} - This would be the most cost-effective option while still providing adequate coverage</li>`;
    //   } else if (element.toLowerCase().includes('apples')) {
    //     packageList += `<li>${element} - This option matches what you currently have with State Farm, but with a 13% rate decrease. That’s an annualized savings of $500</li>`;
    //   } else if (element.toLowerCase().includes('enhanced')) {
    //     if (element.toLowerCase().includes('premier')) {
    //       if (element.toLowerCase().includes('plus')) {
    //         packageList += `<li>${element} - I have increased liability and uninsured motorist limits to the next highest tier there is which is $250,000/$500,000 and included the responsible driver plan, which offers accident and minor violation forgiveness. One accident and one violation is forgiven every 36 months, starts immediately</li>`;
    //       } else {
    //         packageList += `<li>${element} - I have increased liability and uninsured motorist limits to the next highest tier which is $100,000/$300,000</li>`;
    //       }
    //     } else {
    //       packageList += `<li>${element} - On this option all coverages remain the same except I have included a comprehensive/collision deductible of $500</li>`;
    //     }
    //   }
    // });

    // let emailProgram = 'GO telematics program';
    // let arrData = value.proposal.split(' ');
    // let arr = value.proposal.toLowerCase().split(' ');
    // console.log('array', arr)
    // if (arr.includes('with')) {
    //   let testData = arr.indexOf('with');
    //   arrData.splice(0, testData + 1);
    //   emailProgram = arrData.toString().replace(/,/, ' ');
    //   console.log('test dataa', arrData.toString().replace(/,/, ' '))
    // }

    let carrier = value.carrierName;
    let telematicsContent = ``;
    if (carrier === 'Mercury') {
      telematicsContent = `<p>Please note that this policy has a 10% discount already included in the rate for participating in the GO app telematics program. 
      This telematics program does not monitor your location, but it does monitor the time that you drive, how fast you accelerate and your hard braking.  
      Upon your renewal you can be rewarded with up to a 20% discount so rates would come down even further. 
      The program is only required for the first 91 days of your first policy term and then the discount will stick with you for the remainder of your policy life, 
      even following you at each renewal.  The program is not required to write the policy, but we do highly recommend the program.</p>
      <br>`;
    } else if (carrier === 'Progressive') {
      telematicsContent = `<p>Please note that this policy has a 15% discount already included in the rate for participating in the Snapshot telematics program. This telematics program does not monitor your location but it does monitor the following: time that you drive, how fast you accelerate and your hard braking.  Upon your renewal you can be rewarded with up to a 45% discount so rates would come down even further. The program is required for the policy life and the discount will be re-evaluated at each renewal.  The Snapshot program is used in the form of an app or a plug-in device to the vehicle. The program is not required to write the policy, but we do highly recommend the program.</p>
      <br>`;
    } else if (carrier === 'Safeco') {
      telematicsContent = `<p>Please note that this policy has a 10% discount already included in the rate for participating in the RightTrack app telematics program. 
      This telematics program does not monitor your location, but it does monitor the time that you drive, how fast you accelerate and  hard braking.  
      Upon your renewal you can be rewarded with up to a 30% discount so rates would come down even further. 
      The program is only required for the first 90 days of your first policy term and then the discount will stick with you for the remainder of your policy life, following you at each renewal.  
      The program is not required to write the policy, but we do highly recommend the program.</p>
      <br>`;
    } else if (carrier === 'Travelers') {
      telematicsContent = `<p>Please note that this policy has a 10% discount already included in the rate for participating in the Intellidrive app telematics program. 
      This telematics program does not monitor your location, but it does monitor the time that you drive, how fast you accelerate and your hard braking.  
      Upon your renewal you can be rewarded with up to a 30% discount so rates would come down even further. 
      The program is only required for the first 90 days of your first policy term and then the discount will stick with you for the remainder of your policy life, even following you at each renewal.  
      The program is not required to write the policy, but we highly recommend the program.</p>
      <br>`;
    } else {
      telematicsContent = ``;
    }

    this.emailContent = `
      <img src="${this.environmentURL}/assets/email_banner.PNG" width="100%"><br><br>
      Hi ${value?.name},<br><br>
      <p>Thank you for the opportunity to earn your business. We shopped coverage across the full Florida auto insurance market to find our best recommended options. A summary of what I have proposed:</p>
      <ul>
      ${packageList}
      </ul>
      <br>
      <p style="text-align:center">
      <a style="background-color: #007cba;
      border-radius: 6px;
      font-size: 12px;
      font-weight: bold;
      line-height: 20px;
      color: #fff;
      text-decoration: none;
      padding: 11px 26px;
      cursor: pointer;" href="${emailRouteUrl}">Proceed to order page</a>
      </p>
      <br>
      ${telematicsContent}
      <p>The proposals also include the following options:</span></p>
      <ul>
      <li>Bodily Injury Liability- Provides coverage when you are at-fault. It pays for the other party’s medical bills, legal bills, and any other compensatory damages.</li>
      <li>Spilt Limits- For example, $100,000/$300,000- Pays up to $100,000 per person and $300,000 per accident</li>
      <li>Combined Single Limit- A pool of money that can be drawn out of in any amount</li>
      <li>As a rule of thumb, we recommend the per accident limit be greater than or equal to your overall assets. If your assets go far beyond $500,000, we recommend carrying an umbrella.</li>
      <li>Property Damage- Provides coverage when you are at-fault. It pays for the other party’s vehicle damage and any other property damage such as fallen light posts.</li>
      <li>The most common vehicle on the road in America is a Ford F-150, with an average MSRP price of $50,000. If you totaled an F-150 and carried less than $50k in property damage, you would be on the hook to make up the difference.</li>
      <li>Uninsured Motorist Coverage- Provides coverage to you and your family if you were involved in an accident with a hit and run driver, a driver that does not carry insurance, or a driver that does not carry adequate insurance</li>
      <li>Stacked-Multiply the numbers of vehicles by the amount of coverage (3 vehicles at $100,000/$300,000, actually have $300,000/$900,000 of coverage)</li>
      <li>Medical payments- Pays per person the medical bills incurred in an accident to you, your family, and everyone in the car. Pays the amount shown per person.</li>
      <li>Personal Injury Protection- Pays you for injuries, no matter who is at fault </li>
      <li>Full PIP- covers medical costs, lost wages, and death benefits.</li>
      <li>Collision- Provides coverage for damage to your vehicle when you collide with another vehicle</li>
      <li>Comprehensive- Provides coverage for your vehicle due to damage from theft, vandalism, or anything not collision related</li>
      <li>Rental Car Coverage- Provides a daily allowance for a rental car if your vehicle is disabled. Pays up to 30 days.</li>
      <li>Roadside- Provides coverage for towing, jump starts, fuel delivery, etc.</li>
      </ul>
      <br><br>Please review and feel free to give me a call to further discuss`;
    this.campaignId = value?._id;
    this.attachmentFile = value?.packageDoc;
    this.emailDialog = true;
  }

  addEmail(control: any, index: number) {
    control[index].get('email').markAllAsTouched();
    if (
      control[index].get('email').invalid ||
      control[index].get('email').value === ''
    ) {
      this.service.snackbar('Please fill a valid email to add more');
      return;
    }
    const array = this.emailForm.get('emailCC') as FormArray;
    array.push(this.newEmail());
  }

  removeEmail(i: number) {
    const array = this.emailForm.get('emailCC') as FormArray;
    array.removeAt(i);
  }

  newEmail(): FormGroup {
    return this.fb.group({
      email: ['', [Validators.email]],
    });
  }

  sendEmail() {
    if (
      this.emailForm?.get('proposal')?.invalid ||
      this.emailForm?.get('emailCC')?.invalid
    ) {
      this.service.snackbar('Please fill a valid fields');
      return;
    }
    this.submitting = true;
    const emails: string[] = [];
    this.emailForm.value.emailCC.forEach((element: { email: string }) => {
      emails.push(element.email);
    });
    const payload = {
      campaignId: this.campaignId,
      emailContent: this.emailContent,
      emailCC: emails,
      subject: this.emailForm.value.proposal,
    };
    this.service.email(payload).subscribe(
      () => {
        this.emailForm.reset();
        const control = <FormArray>this.emailForm.controls['emailCC'];
        for (let i = control.length - 1; i > 0; i--) {
          control.removeAt(i);
        }
        this.submitting = false;
        this.emailDialog = false;
        this.service.snackbar('Email sent successfully');
      },
      () => {
        this.submitting = false;
        this.service.snackbar('Error');
      }
    );
  }

  closeEmailDialog() {
    this.emailDialog = false;
    this.emailForm.reset();
    const control = <FormArray>this.emailForm.controls['emailCC'];
    for (let i = control.length - 1; i > 0; i--) {
      control.removeAt(i);
    }
  }

  smsPreview(value: any) {
    const smsRouteUrl = `${this.environmentURL}/preview/${value?.merchantId}/${value?._id}`;
    this.emailForm.patchValue({
      proposal: 'HH Insurance - Private Passenger Automobile Proposal',
    });
    const smsContent = `Hi ${value?.name},
      Thank you for the opportunity to earn your business. We shopped coverage across the full Florida auto insurance market to find our best recommended options. 
      Click here: ${smsRouteUrl}`;
    this.emailForm.patchValue({
      smsMessage: smsContent,
    });
    this.campaignId = value?._id;
    this.smsDialog = true;
  }

  sendSms() {
    if (
      this.emailForm?.get('smsMessage')?.invalid ||
      this.emailForm?.get('proposal')?.invalid
    ) {
      this.service.snackbar('Please fill a valid fields');
      return;
    }
    this.submitting = true;
    const payload = {
      campaignId: this.campaignId,
      textContent: this.emailForm.value.smsMessage,
      subject: this.emailForm.value.proposal,
    };
    this.service.sms(payload).subscribe(
      () => {
        this.emailForm.reset();
        this.submitting = false;
        this.smsDialog = false;
        this.service.snackbar('SMS sent successfully');
      },
      () => {
        this.submitting = false;
        this.service.snackbar('Error');
      }
    );
  }

  closeSMSDialog() {
    this.smsDialog = false;
    this.emailForm.reset();
  }

  pageChange(e: any): void {
    this.selectedQuote = [];
    this.deleteSelect.reset(false);
    this.currentPageIndex(e.pageIndex);
    this.pageOffset = this.paginationOffset(e['pageIndex'], e['pageSize']);
    const page = {
      limit: this.pageSize,
      offset: this.pageOffset,
    };
    this.pagination(page);
  }

  currentPageIndex(index: number) {
    this.currentPage = index;
  }

  paginationOffset(currentPage: number, itemsPerPage: number): number {
    let offset = currentPage * itemsPerPage + 1;
    return (offset = offset < 0 ? offset : offset - 1);
  }

  pagination(e: { limit?: number; offset: number }) {
    this.pageOffset = e.offset ? e.offset : 0;
    this.getData(this.sortOrderName, '', '');
  }

  delete() {
    this.service
      .deleteQuote(this.merchantId, this.selectedQuote.join(','))
      .subscribe(
        (res) => {
          if (res.status) {
            this.selectedQuote = [];
            this.pageOffset = 0;
            this.currentPage = 0;
            this.searchText.reset('');
            this.deleteSelect.reset(false);
            this.getData(this.sortOrderName, '', this.searchText);
            this.service.snackbar('Offer Deleted');
          }
        },
        () => {
          this.service.snackbar('Error');
        }
      );
  }
}
