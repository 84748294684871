import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from "ngx-ui-loader";

@Injectable({
  providedIn: 'root',
})
export class WizzipayService {
  environment: any;

  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    public ngxService: NgxUiLoaderService
  ) {
    this.environment = environment;
  }

  snackbar(message: string, duration?: any) {
    return this.snackBar.open(message, 'close', {
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
      duration: duration || 1500,
      panelClass: ['snackbarStyle'],
    });
  }

  login(data: any): Observable<any> {
    return this.http.post(
      this.environment.autoPaymentServiceURL + '/account/login',
      data
    );
  }

  register(data: any): Observable<any> {
    return this.http.post(
      this.environment.autoPaymentServiceURL + '/account/create',
      data
    );
  }

  getcampaignData(
    id: any,
    sort: any,
    status: any,
    limit: any,
    offset: any,
    value: string
  ): Observable<any> {
    return this.http.get(
      this.environment.autoPaymentServiceURL +
        `/campaign/${id}?sort=${sort}&status=${status}&offset=${offset}&limit=${limit}&value=${value}`
    );
  }

  getMerchantData(id: any): Observable<any> {
    return this.http.get(
      this.environment.autoPaymentServiceURL + `/merchant/${id}`
    );
  }

  archieveOffer(merchantId: any, campaignId: any): Observable<any> {
    return this.http.put(
      this.environment.autoPaymentServiceURL +
        `/campaign/${merchantId}/${campaignId}/archive`,
      ''
    );
  }

  getCardDetails(id: any): Observable<any> {
    return this.http.get(
      this.environment.autoPortalURL + `/api/vault/users/${id}`
    );
  }

  deleteQuote(merchantId: any, data: any): Observable<any> {
    return this.http.delete(
      this.environment.autoPaymentServiceURL +
        `/campaign/${merchantId}?ids=${data}`
    );
  }

  // Order Setup

  parseFiles(data: any): Observable<any> {
    return this.http.post(
      this.environment.autoPortalURL + `/api/parser/file`,
      data
    );
  }

  uploadFiles(data: any): Observable<any> {
    return this.http.post(
      this.environment.autoPaymentServiceURL + '/file/upload',
      data
    );
  }

  sumbitOrder(data: any, merchantId: any): Observable<any> {
    return this.http.post(
      this.environment.autoPaymentServiceURL + `/campaign/${merchantId}`,
      data
    );
  }

  getdata(merchantId: any, campaignId: any): Observable<any> {
    return this.http.get(
      this.environment.autoPaymentServiceURL +
        `/campaign/${merchantId}/${campaignId}`
    );
  }

  updateOrder(data: any, merchantId: any, campaignId: any): Observable<any> {
    return this.http.put(
      this.environment.autoPaymentServiceURL +
        `/campaign/${merchantId}/${campaignId}`,
      data
    );
  }

  email(data: any): Observable<any> {
    return this.http.post(
      this.environment.autoPaymentServiceURL + '/notification/campaign',
      data
    );
  }

  sms(data: any): Observable<any> {
    return this.http.post(
      this.environment.autoPaymentServiceURL + '/notification/campaign/message',
      data
    );
  }

  orderData(data: any): Observable<any> {
    return this.http.post(
      this.environment.autoPaymentServiceURL + '/payment/pay',
      data
    );
  }

  transactiondata(id: any): Observable<any> {
    return this.http.get(
      this.environment.autoPaymentServiceURL + `/payment/pay/${id}`
    );
  }

  bankData(data: any): Observable<any> {
    return this.http.post(
      this.environment.autoPaymentServiceURL + '/payment/pay/ach',
      data
    );
  }

  postTransaction(data: any, id: any): Observable<any> {
    return this.http.post(
      this.environment.autoPaymentServiceURL + `/payment/pay/${id}`,
      data
    );
  }

  // TRANSACTION API

  getAllTransaction(): Observable<any> {
    return this.http.get(this.environment.transactionURL + `/transaction`);
  }

  getTransaction(id: any): Observable<any> {
    return this.http.get(
      this.environment.transactionURL + `/transaction/${id}`
    );
  }

  approveTransaction(id: any, data: any): Observable<any> {
    return this.http.post(
      this.environment.transactionURL + `/transaction/${id}/approve`,
      data
    );
  }
}
