import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-setup',
  templateUrl: './order-setup.component.html',
  styleUrls: ['./order-setup.component.css'],
})
export class OrderSetupComponent implements OnInit {
  productDetail = this.fb.group({
    customer: this.fb.group({
      firstName: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z]{1,30}$/)],
      ],
      lastName: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z- ]{1,30}$/)],
      ],
      email: ['', [Validators.required, Validators.email]],
    }),
    // driverPhone: this.fb.array([[this.newDriverNumber()], [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)]]),
    driverPhone: this.fb.array([this.newDriverNumber()]),
    insuranceType: [{ value: 'Auto', disabled: false }],
    proposal: '',
    packageDoc: this.fb.group({
      url: [''],
      name: [''],
      size: [''],
    }),
    agent: this.fb.group({
      firstName: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z]{1,30}$/)],
      ],
      lastName: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z- ]{1,30}$/)],
      ],
      number: [
        '',
        [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)],
      ],
      email: ['', [Validators.required, Validators.email]],
    }),
    policyStartDate: [''],
    carrier: ['', [Validators.required]],
  });
  startDate = new Date();
  environmentURL = '';
  merchantId = '';
  campaignId = '';
  carrierValue = '';
  update = false;
  carrierSelected = false;
  targetOptionList = [
    {
      name: 'Auto',
      value: 'Auto',
      selected: false,
    },
    {
      name: 'Golf Cart',
      value: 'Golf Cart',
      selected: false,
    },
    {
      name: 'Motorcycle',
      value: 'Motorcycle',
      selected: false,
    },
    {
      name: 'Boat',
      value: 'Boat',
      selected: false,
    },
    {
      name: 'Umbrella',
      value: 'Umbrella',
      selected: false,
    },
    {
      name: 'House Owner',
      value: 'House Owner',
      selected: false,
    },
  ];
  carrierList = [
    {
      name: 'Mercury',
      value: 'Mercury',
      selected: false,
    },
    {
      name: 'Metlife',
      value: 'Metlife',
      selected: false,
    },
    {
      name: 'Progressive',
      value: 'Progressive',
      selected: false,
    },
    {
      name: 'Safeco',
      value: 'Safeco',
      selected: false,
    },
    {
      name: 'Vault',
      value: 'Vault',
      selected: false,
    },
    {
      name: 'Bristol West',
      value: 'Bristol West',
      selected: false,
    },
    {
      name: 'Hagerty',
      value: 'Hagerty',
      selected: false,
    },
    {
      name: 'Travelers',
      value: 'Travelers',
      selected: false,
    },
    {
      name: 'American Modern',
      value: 'American Modern',
      selected: false,
    },
  ];
  parsedTableDatas: any[] = [];
  parsedTable: any[] = [];
  submitting = false;
  @Output() public getProductData = new EventEmitter<string>();

  constructor(
    public fb: FormBuilder,
    public activatedRoute: ActivatedRoute,
    public service: WizzipayService,
    private datepipe: DatePipe
  ) {
    this.environmentURL = environment.autoPortalURL;
  }

  get customer(): FormGroup {
    return this.productDetail.get('customer') as FormGroup;
  }

  get driverPhone(): FormArray {
    return this.productDetail.get('driverPhone') as FormArray;
  }

  get packageDoc(): FormGroup {
    return this.productDetail.get('packageDoc') as FormGroup;
  }

  get agent(): FormGroup {
    return this.productDetail.get('agent') as FormGroup;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.merchantId = params['id'];
      this.campaignId = params['campaignId'];
      if (this.merchantId && this.campaignId) {
        this.getPreviewData();
      }
    });
  }

  selectMethod(val: any) {
    this.carrierValue = val;
    val == null
      ? (this.carrierSelected = false)
      : (this.carrierSelected = true);
  }

  addDriver(control: any, index: number) {
    control[index].get('number').markAllAsTouched();
    if (control[index].get('number').invalid) {
      this.service.snackbar('Please fill this number to add more');
      return;
    }
    const array = this.productDetail.get('driverPhone') as FormArray;
    array.push(this.newDriverNumber());
  }

  removeDriver(i: number) {
    const array = this.productDetail.get('driverPhone') as FormArray;
    array.removeAt(i);
  }

  newDriverNumber(): FormGroup {
    return this.fb.group({
      number: [
        '',
        [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)],
      ],
    });
  }

  getPreviewData() {
    this.service.getdata(this.merchantId, this.campaignId).subscribe((res) => {
      this.productDetail.controls['insuranceType'].disable();
      if (res.data) {
        if (res?.data?.policyStartDate) {
          res.data['policyStartDate'] = this.datepipe.transform(
            res?.data?.policyStartDate,
            'MM/dd/yyyy'
          );
        }
        this.update = true;
        this.productDetail.patchValue(res.data);
        if (res?.data?.driverPhone?.length > 0) {
          this.productDetail.setControl(
            'driverPhone',
            this.resDriverNumber(res?.data?.driverPhone)
          );
        }
        if (res.data.package.length > 0) {
          // QUOTE TABLE DATAS START
          this.parsedTableDatas = [];
          this.parsedTableDatas = res.data.package;
          // const payload: { [key: string]: any } = {};
          // var headers: string[] = [];
          // const colPackage: string[] = []; //Table Package Name
          // const colValues: string[][] = [];
          // res.data.package.forEach(
          //   (sub: { [key: string]: string }, index: number) => {
          //     const rowValues: string[] = [];
          //     for (const key in res.data.package[0]) {
          //       if (key == 'name') {
          //         headers.push(sub.name);
          //       } else if (index == 0) {
          //         colPackage.push(key);
          //       }
          //       if (key != 'name') {
          //         rowValues.push(sub[key]);
          //       }
          //     }
          //     colValues.push(rowValues);
          //   }
          // );
          // payload['headers'] = headers;
          // payload['colPackage'] = colPackage;
          // payload['colValues'] = colValues;
          // this.parsedTableDatas.push(payload);
          // let testObj:any = {
          //   head:headers
          // };
          // testObj.head = headers
          // console.table(this.parsedTableDatas)
          // console.log(testObj)
          // // QUOTE TABLE DATAS END
        }
      }
    });
  }

  resDriverNumber(driverNum: Array<{ [key: string]: string }>): FormArray {
    const formArray = new FormArray([]);
    driverNum.forEach((element) => {
      formArray.push(
        this.fb.group({
          number: [
            element.number,
            [Validators.required, Validators.pattern(/^[0-9-() ]{14}$/)],
          ],
        })
      );
    });
    return formArray;
  }

  getTable() {
    if (this.parsedTable.length > 0) {
      setTimeout(() => {
        this.formatTable();
      }, 0);
    }
  }

  getPDFRes(value: any) {
    if (value != 'uploading' && value?.response) {
      const cusName = value?.response?.customerName.split(' ');
      this.customer.patchValue({
        firstName: cusName[0] || '',
        lastName: cusName[1] || '',
        email: value?.response?.customerEmail,
      });
      const agName = value?.response?.agentName.split(' ');
      this.agent.patchValue({
        firstName: agName[0] || '',
        lastName: agName[1] || '',
        email: value?.response?.email,
        number: value?.response?.phone,
      });
      this.packageDoc.patchValue({
        name: value?.fileName,
        size: value?.fileSize,
      });
      if (value?.response?.phone) {
        const driverPhone = [];
        const payload = {
          number: value?.response?.customerPhone,
        };
        driverPhone.push(payload);
        this.productDetail.setControl(
          'driverPhone',
          this.resDriverNumber(driverPhone)
        );
      }
      this.parsedTable = value?.response?.proposals;
      if (this.productDetail.getRawValue().insuranceType) {
        this.productDetail.controls['insuranceType'].disable();
        this.formatTable();
      }
    }
  }

  formatTable() {
    this.parsedTableDatas = [];
    this.parsedTable?.forEach((element) => {
      if (
        element.proposal
          .toLowerCase()
          .includes(
            this.productDetail.getRawValue().insuranceType.toLowerCase()
          ) //To check if insurance type exists in proposal name
      ) {
        this.productDetail.patchValue({ proposal: element.proposal });
        // QUOTE TABLE DATAS START
        const payload: { [key: string]: any } = {};
        const colPackage: string[] = []; //Table Package Name
        const colValues: string[][] = []; //Package Column Values
        payload['headers'] = element?.packages;
        //Getting Carrier Value Start
        // element[element?.packages[0]].forEach(
        //   (keyData: { key: string; value: string }) => {
        //     if (keyData.key == 'Carrier') {
        //       this.productDetail.patchValue({ carrier: keyData.value }); //Map Carrier Value
        //       if (this.productDetail.value.carrier) {
        //         element[element?.packages[0]].pop(); //Remove Carrier Value after patching
        //       }
        //     }
        //   }
        // );
        //Getting Carrier Value End
        element?.packages.forEach((header: string, index: number) => {
          const rowValues: string[] = [];
          element[header].forEach((data: { key: string; value: string }) => {
            if (index === 0) {
              colPackage.push(data.key);
            }
            payload['colPackage'] = colPackage;
            rowValues.push(data.value);
          });
          colValues.push(rowValues);
          payload['colValues'] = colValues;
        });
        this.parsedTableDatas.push(payload);
        // QUOTE TABLE DATAS END
      }
    });
  }

  getSecondRes(value: { data: { url: string } }) {
    this.packageDoc.patchValue({ url: value?.data?.url });
  }

  deletedImgRes() {
    this.parsedTableDatas = [];
    const control = <FormArray>this.productDetail.controls['driverPhone'];
    control.removeAt(0);
    this.productDetail.reset({
      insuranceType: 'Auto',
      carrier: this.carrierValue,
    });
    control.push(this.newDriverNumber());
    this.productDetail.controls['insuranceType'].enable();
  }

  returnZero() {
    return 0;
  }

  trackByFn(item: number) {
    return item;
  }

  submit() {
    this.productDetail.get('carrier')?.markAsTouched();
    if (this.productDetail.get('carrier')?.invalid) {
      this.service.snackbar('Please Select the Carrier Name');
      return;
    }
    this.productDetail.markAllAsTouched();
    if (this.parsedTableDatas.length == 0) {
      this.service.snackbar('Please upload Insurance Document');
      return;
    }
    if (this.productDetail.invalid) {
      this.service.snackbar('Please fill all the fields');
      return;
    }
    // Updating new parsed data format start
    // const formatePackages: { [key: string]: string }[] = [];
    // this.parsedTableDatas.forEach((element, index: number) => {
    //   if (index === 0) {
    //     element.headers.forEach((head: string, headindex: number) => {
    //       const obj: { [key: string]: string } = {};
    //       obj['name'] = head;
    //       element.colPackage.forEach((pack: string, packindex: number) => {
    //         element.colValues[headindex].forEach(
    //           (colValue: string, valueindex: number) => {
    //             if (packindex === valueindex) {
    //               obj[pack] = colValue;
    //               if(obj[pack]){
    //                 console.log('yes')
    //               }
    //               console.log('inside object', obj)
    //             }
    //           }
    //         );
    //       });
    //       console.log('object', obj)
    //       formatePackages.push(obj);
    //     });
    //   }
    // });
    // Updating new parsed data format end
    this.submitting = true;
    const payload = this.productDetail.getRawValue();
    payload['package'] = this.parsedTableDatas;
    //payload['package'] = formatePackages;
    if (this.campaignId) {
      this.service
        .updateOrder(payload, this.merchantId, this.campaignId)
        .subscribe(
          (res) => {
            this.submitting = false;
            this.service.snackbar('Offer Updated');
            this.getProductData.emit(res.data);
          },
          (err) => {
            this.submitting = false;
            this.service.snackbar(err.message);
          }
        );
    } else {
      this.service.sumbitOrder(payload, this.merchantId).subscribe(
        (res) => {
          this.submitting = false;
          this.campaignId = res.data._id;
          this.service.snackbar('Offer Created');
          this.getProductData.emit(res.data);
        },
        (err) => {
          this.submitting = false;
          this.service.snackbar(err.message);
        }
      );
    }
  }
}
