import { Component, OnInit } from '@angular/core';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css'],
})
export class SetupComponent implements OnInit {
  merchantId = '';
  campaignId = '';
  url = '';
  previewRes: any;
  environmentURL = '';

  constructor(
    public activatedRoute: ActivatedRoute,
    public service: WizzipayService,
    public router: Router
  ) {
    this.environmentURL = environment.autoPortalURL;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.merchantId = params['id'];
      this.campaignId = params['campaignId'];
    });
  }

  updatedProductData(data: any, stepper: MatStepper) {
    if (data) {
      this.url = `${this.environmentURL}/orderPage/${data.merchantId}/${data._id}`;
      this.merchantId = data.merchantId;
      this.goForward(stepper);
      this.getPreviewData(data.merchantId, data._id);
    }
  }

  getPreviewData(merchant: string, campaign: string) {
    this.service.getdata(merchant, campaign).subscribe((res) => {
      this.previewRes = res.data;
      console.log(res.data)
    });
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  goCheckout() {
    this.router.navigate([`/merchant/campaignList/${this.merchantId}`]);
  }
}
