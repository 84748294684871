<div class="container-fluid">
    <div class="row row-height">
        <ng-container *ngIf="dataRes.status =='open'">
            <div class="col-md-7 px-0">
                <div class="content-left-wrapper opacity-mask">
                    <div class="col-md-12">
                        <div class="tableContainer">
                            <h6>{{ dataRes?.package?.insuranceType }} Insurance Option</h6>
                            <table class="table table-bordered mb-0">
                                <thead>
                                    <!-- <tr *ngFor="let data of quoteDatas; let first = first">
                                        <ng-container *ngFor="let item of data | keyvalue: returnZero">
                                            <td *ngIf="first && item.key != 'selected'">
                                                <b> {{ data[item.key] }} </b>
                                            </td>
                                        </ng-container>
                                    </tr> -->
                                    <th>Package</th>
                                    <th *ngFor="let column of quoteDatas[0]?.headers">
                                        {{column}}
                                    </th>
                                </thead>
                                <tbody>
                                    <!-- <tr *ngFor="let data of quoteDatas; let first = first">
                                        <ng-container *ngFor="let item of data | keyvalue: returnZero">
                                            <td *ngIf="!first && item.key != 'selected'"
                                                [ngClass]="{'columnColor': data['selected']}">
                                                {{ data[item.key] }}
                                            </td>
                                        </ng-container>
                                    </tr> -->
                                    <tr *ngFor="let row of quoteDatas[0]?.colPackage; index as i">
                                        <td>{{row}}</td>
                                        <td *ngFor="let column of quoteDatas[0]?.headers; index as j"
                                            class="columnColor">
                                            {{quoteDatas[0]?.colValues[j][i]}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5 d-flex flex-column content-right">
                <div class="container my-auto py-5">
                    <div class="row justify-content-center">
                        <div class="col-lg-10 col-xl-10 mx-auto">
                            <div class="mb-3">
                                <a class="urlStyles" href="{{ orderpage }}">
                                    <i class="fa fa-chevron-left pe-1" aria-hidden="true"></i>
                                    back to order page
                                </a>
                            </div>
                            <div class="logoImgStyle mb-3">
                                <img src="assets/insurance.png" width="100%" />
                            </div>
                            <div class="contentCard p-4 mb-3">
                                <div class="mb-3">
                                    <h4>${{ transactionAmount | number }}</h4>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3">
                                            <span class="text-color-gray">To</span>
                                        </div>
                                        <div class="col-lg-9">
                                            <span class="text-font14"> HH Insurance</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="contentCard">
                                <h6 class="px-4 pt-3 mb-0">{{dataRes.paymentMethod}}</h6>
                                <div class="col-lg-12 px-4 py-3"
                                    *ngIf="dataRes.paymentMethod === 'Electronic Funds Transfer'">
                                    <form [formGroup]="bankDetails" class="row">
                                        <div class="col-lg-12 mb-3">
                                            <insurance-input inputId="accountName" label="Account Name"
                                                formControlName="accountName">
                                            </insurance-input>
                                            <div
                                                *ngIf="bankDetails?.get('accountName')?.touched && bankDetails?.get('accountName')?.errors">
                                                <span class="p-error"
                                                    *ngIf="bankDetails?.get('accountName')?.hasError('required')">
                                                    Account name is required
                                                </span>
                                                <span class="p-error"
                                                    *ngIf="bankDetails?.get('accountName')?.hasError('pattern')">
                                                    Please enter a valid account name
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 mb-3">
                                            <insurance-input inputId="accountType" label="Account Type"
                                                formControlName="accountType">
                                            </insurance-input>
                                            <div
                                                *ngIf="bankDetails?.get('accountType')?.touched && bankDetails?.get('accountType')?.errors">
                                                <span class="p-error"
                                                    *ngIf="bankDetails?.get('accountType')?.hasError('required')">
                                                    Account type is required
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 mb-3">
                                            <insurance-input inputId="routingNumber" label="Routing Number"
                                                formControlName="routingNumber">
                                            </insurance-input>
                                            <div
                                                *ngIf="bankDetails?.get('routingNumber')?.touched && bankDetails?.get('routingNumber')?.errors">
                                                <span class="p-error"
                                                    *ngIf="bankDetails?.get('routingNumber')?.hasError('required')">
                                                    Routing number is required
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mb-3">
                                            <insurance-input inputId="accountNumber" label="Account Number"
                                                formControlName="accountNumber">
                                            </insurance-input>
                                            <div
                                                *ngIf="bankDetails?.get('accountNumber')?.touched && bankDetails?.get('accountNumber')?.errors">
                                                <span class="p-error"
                                                    *ngIf="bankDetails?.get('accountNumber')?.hasError('required')">
                                                    Account number is required
                                                </span>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="col-lg-12 text-center">
                                        <insurance-button btnWidth="150" [loading]="submittingBank"
                                            (onClick)="submitBank()" label="Pay">
                                        </insurance-button>
                                    </div>
                                </div>
                                <div class="col-lg-12 px-4 py-3" *ngIf="dataRes.paymentMethod === 'Credit Card'">
                                    <form [formGroup]="creditDetails" class="row">
                                        <div class="col-lg-12 mb-3">
                                            <insurance-input inputId="cardNumber" label="Credit Card Number"
                                                formControlName="cardNumber" maxlength="16">
                                            </insurance-input>
                                            <div
                                                *ngIf="creditDetails?.get('cardNumber')?.touched && creditDetails?.get('cardNumber')?.errors">
                                                <span class="p-error"
                                                    *ngIf="creditDetails?.get('cardNumber')?.hasError('required')">
                                                    Card number is required
                                                </span>
                                                <span class="p-error"
                                                    *ngIf="creditDetails?.get('cardNumber')?.hasError('pattern')">
                                                    Please enter a valid card number
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mb-3">
                                            <insurance-input inputId="cardName" label="Name on Card"
                                                formControlName="cardName">
                                            </insurance-input>
                                            <div
                                                *ngIf="creditDetails?.get('cardName')?.touched && creditDetails?.get('cardName')?.errors">
                                                <span class="p-error"
                                                    *ngIf="creditDetails?.get('cardName')?.hasError('required')">
                                                    Card name is required
                                                </span>
                                                <span class="p-error"
                                                    *ngIf="creditDetails?.get('cardName')?.hasError('pattern')">
                                                    Please enter a valid card name
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 mb-3">
                                            <insurance-input inputId="cvv" label="CVV" formControlName="cvv"
                                                maxlength="3">
                                            </insurance-input>
                                            <div
                                                *ngIf="creditDetails?.get('cvv')?.touched && creditDetails?.get('cvv')?.errors">
                                                <span class="p-error"
                                                    *ngIf="creditDetails?.get('cvv')?.hasError('required')">
                                                    CVV is required
                                                </span>
                                                <span class="p-error"
                                                    *ngIf="creditDetails?.get('cvv')?.hasError('pattern')">
                                                    Please enter a valid CVV
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-7 mb-3">
                                            <insurance-input-date inputId="expiry" label="Expiry date"
                                                formControlName="expiry" dateFormat="mm/yy" view="month"
                                                [minDate]="startDate">
                                            </insurance-input-date>
                                            <div
                                                *ngIf="creditDetails?.get('expiry')?.touched && creditDetails?.get('expiry')?.errors">
                                                <span class="p-error"
                                                    *ngIf="creditDetails?.get('expiry')?.hasError('required')">
                                                    Expiry date is required
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 mb-3">
                                            <insurance-input inputId="zipCode" label="Zip Code"
                                                formControlName="zipCode" maxlength="5">
                                            </insurance-input>
                                            <div
                                                *ngIf="creditDetails?.get('zipCode')?.touched && creditDetails?.get('zipCode')?.errors">
                                                <span class="p-error"
                                                    *ngIf="creditDetails?.get('zipCode')?.hasError('required')">
                                                    Zipcode is required
                                                </span>
                                                <span class="p-error"
                                                    *ngIf="creditDetails?.get('zipCode')?.hasError('pattern')">
                                                    Please enter a valid zipcode
                                                </span>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="col-lg-12 text-center">
                                        <insurance-button btnWidth="150" (onClick)="submitCredit()" label="Pay"
                                            [loading]="submittingCredit">
                                        </insurance-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="dataRes.status =='paid'">
            <div class="container d-flex justify-content-center align-items-center h-100">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <div class="jumbotron mb-5">
                            <img src="assets/insurance.png" width="180px">
                        </div>
                        <h1 class="mb-3">Your order transaction is submitted</h1>
                        <a class="urlStyles f20" href="{{ orderpage }}">
                            View your order
                        </a>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div class="modalWithClose">
    <insurance-popup [displayModal]="displayDialog" [showHeader]="false" iconBgColor="#e5f5ec" iconClass="pi pi-check"
        iconColor="#27AE60" message="Transaction Submitted">
        <div class="mt-3">
            <insurance-button label="Ok" buttonId="submit" (onClick)="routeBack()" btnWidth="150">
            </insurance-button>
        </div>
    </insurance-popup>
</div>